import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Experience from 'components/Experience';
import Hero from 'components/Hero';
import Blurb from 'components/Blurb';
import Services from 'components/Services';
import Education from 'components/Education';
import Skills from 'components/Skills';
import SocialIcons from 'components/SocialIcons';

const ResumePage: React.FC = () => {
  const { markdownRemark } = useStaticQuery(graphql`
      query {
        markdownRemark(frontmatter: { category: { eq: "resume section" } }) {
          html
          frontmatter {
            title
            subtitle
            seotitle
            seodescription
          }
        }
      }
  `);

  const subtitle = markdownRemark.frontmatter.subtitle;
  const title = markdownRemark.frontmatter.title;
  const html = markdownRemark.html;
  const seotitle = markdownRemark.frontmatter.seotitle;
  const seodescription = markdownRemark.frontmatter.seodescription;
  
  return (
    <Layout>
      <SEO title={seotitle} description={seodescription}/>
      <Hero title={title} subtitle={subtitle}/>
      <Services />
      <Blurb html={html}/>
      <Experience />
      <Education />
      <Skills />
      <SocialIcons />
    </Layout>
  );
};

export default ResumePage;
