import styled from 'styled-components';

export const Timeline = styled.div`
    margin: 0 auto;
    position: relative;
    z-index: 1;
    margin-bottom: 5rem;
    
    p, h1, h2, h3, h4, h5, h6, hr {
        position: relative;
        z-index: 1;
    }

    &:before,
    &:after {
        display: block;
        content: '';
        clear: both;
    }

    .clear {
        clear: both;
    }

    .gatsby-image-wrapper {
        width: 24rem;
        display: inline-block;
        vertical-align: top;
        padding-bottom: 3rem;
    }

    .post-content {
        padding: 1em 2em 4em 2em;
        line-height: 1.6em; 
        margin: 0 auto;
        margin-top: 19rem;

        @media (max-width: 450px) {
            margin-top: 15rem;
        }

        @media (min-width: 450px) and (max-width: 800px) {
            margin-top: 18rem;
        }

        @media (min-width: 800px) {
            margin-top: 24rem;
        }

        .narrow {
            max-width: 32rem;
            margin: 0 auto !important;
        }

        em {
            font-style: italic;
        }

        h1, h2, h3, h4, h5, h6, p, li {
            font-family: 'Source', sans-serif;
        }

        h2, h3, h4, strong {
            font-family: 'Source-Bold', sans-serif; 
        }

        h2 {
            margin: 2.4rem 0 1rem;
            font-size: 2.8rem;
            line-height: 1.4em;
        }

        h3 {
            margin: 2.4rem 0 1rem;
            font-size: 2.4rem;
            line-height: 1.4em;
        }

        h4 {
            margin: 2.4rem 0 1rem;
            font-size: 1.2rem;
        }

        p {
            margin: 1.6rem 0 0;
            line-height: 3rem;
            font-size: 2rem;
        }

        a {
            color: green;
        }
    }

`;

export const Details = styled.div`

    @media (min-width: 800px) {
        display: inline-block;
        vertical-align: top;
        text-align: right;
        width: 40%;
        padding-right: 5rem;

        .headlines {
            width: 80%;
            display: inline-block;
            vertical-align: top;
            padding-left: 2rem;
        }
    }
    
`;

export const Content = styled.div`
    display: inline-block;
    vertical-align: top;
    line-height: 3rem;
    font-family: 'Source', sans-serif;
    font-size: 2rem;

    p {
        margin-bottom: 1rem;
    }

    @media (min-width: 800px) {
        width: 50%;
    }

    @media (max-width: 800px) {
        display: none;
    }
`;

export const Mobile = styled.div`
    display: inline-block;
    vertical-align: top;
    line-height: 3rem;
    font-family: 'Source', sans-serif;
    font-size: 2rem;

    p {
        margin-bottom: 1rem;
    }

    @media (min-width: 800px) {
        display: none;
    }
`;

export const Title = styled.h3`
    font-size: 2.5rem;
    font-family: 'Source-Bold', sans-serif;
    padding-top: 2rem;
`;

export const Company = styled.h3`
    font-size: 3.5rem;
    font-family: 'Source-Bold', sans-serif;
    padding-top: 2rem;
`;

export const Subtitle = styled.h4`
    font-size: 2rem;
    font-family: 'Source', sans-serif;

    @media (max-width: 450px) {
        display: none;
    }
`;

export const Date = styled.p`
    line-height: 3rem;
    font-family: 'Source', sans-serif;
    font-size: 1.8rem;
    padding-bottom: 2rem;
    color: #484b48;
`;

export const Point = styled.span`
`;

export const Jobs = styled.div`
    padding-top: 0.8rem;
    font-family: 'Source', sans-serif;

    @media (max-width: 800px) {
        display: none !important;
    }

    h5 {
        font-size: 2rem;
        color: #484b48;
    }
    p {
        font-size: 1.5rem;
        color: #484b48;
    }
`;

export const TagContainer = styled.div`
    display: flex;
    justify-content: right;
    flex-wrap: wrap;
    margin-top: 1rem;

    @media (max-width: 800px) {
        display: none !important;
    }

   a:first-child  > div {
        background-color: rgba(170,170,170,.5);
        transition: background-color .2s ease-in-out;

        &:hover {
            background-color: rgba(190,190,190,.8)
        }

    }
`;

export const Tag = styled.div`
    margin: .3rem .3rem;
    background-color: grey;
    border-radius: 3rem !important;
    transition: background-color .3s ease-in-out;

    &.Activity {
        background-color: rgba(0,0,0,.45)
    }
    &.Industry {
        background-color: rgba(0,0,0,.53)
    }
    &.Audience {
        background-color: rgba(0,0,0,.68)
    }
    &.Type {
        background-color: rgba(0,0,0,.8)
    }

    &:hover {
        background-color: rgba(255,255,255,.9)
    }

    p {
        font-family: 'Source', sans-serif;
        padding: .07rem 1.7rem .07rem 1.7rem;
        line-height: 3rem;
        font-size: 1.5rem;
        color: white;
        text-decoration: none;
        .svg {
            position: absolute;
            top: .9rem;
            text-decoration: none;
        }
    }
`;