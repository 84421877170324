import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Timeline from 'components/ui/Timeline';
import Container from 'components/ui/Container';
import FormatHtml from 'components/utils/FormatHtml';

import * as Styled from './styles';

const Experience = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "experiences" } } }
        sort: { order: DESC, fields: fileAbsolutePath }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              company
              title
              position
              job
              map
              tag
              startDate
              hastags
              endDate
              logo {
                childImageSharp {
                  fixed (width: 200) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const experiences = allMarkdownRemark.edges;
  
  function getDesktopAndMobileCopy (html) {
    return html.split('<p>SPLIT</p>')
  }

  function mapJobs (arrayOfJobs) {
    return arrayOfJobs.reduce((acc, el, i) => {
      i % 3 === 0 
        ? acc.push({title: el, start: null, end: null})
        : i % 3 === 1
        ? acc[(i - 1) / 3].start = el
        : acc[(i - 2) / 3].end = el;
      return acc;
    }, []);
  }

  function mapTags(arr) {
    let result = [];
    for (let i = 0; i < arr.length; i += 2) {
      result.push({ name: arr[i], category: arr[i + 1] });
    }
    
    return result;
  }
  
  const experiencesWithJobs = experiences.reduce((acc, el) => {
    if (el.node.frontmatter.map) {
      el.node.frontmatter.jobs = mapJobs(el.node.frontmatter.job);
    } else {
      el.node.frontmatter.jobs = [];
    }
    if (el.node.frontmatter.hastags) {
      el.node.frontmatter.tags = mapTags(el.node.frontmatter.tag);
    } else {
      el.node.frontmatter.tags = [];
    }
    const newHtml = getDesktopAndMobileCopy(el.node.html)
    el.node.desktop = newHtml[0];
    el.node.mobile = newHtml[1] || newHtml[0];
    acc.push(el);
    return acc;
  }, []);

  return (
    <Container section>
     <Styled.Experiences>
        <Styled.Titles>
          <h2>Professional Life</h2>
        </Styled.Titles>
        {experiencesWithJobs.map((item) => {
          const {
            id,
            desktop,
            mobile,
            frontmatter: { company, title, startDate, endDate, position, logo, jobs, tags }
          } = item.node;
          
          return (
            <Timeline
              key={id}
              title={title}
              company={company}
              subtitle={position}
              html={<FormatHtml content={desktop} />}
              mobile={<FormatHtml content={mobile} />}
              startDate={startDate}
              endDate={endDate}
              logo={logo}
              jobs={jobs}
              tags={tags}
            />
          );
        })}
     </Styled.Experiences>
    </Container>
  );
};

export default Experience;
