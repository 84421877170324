import styled from 'styled-components';

export const Experiences = styled.section`
    max-width: 133rem;
    margin: 0 auto;
    margin-bottom: 6rem;
    position: relative;
    z-index: 1;

    a {
        color: green !important;
        text-decoration: underline !important;
    }

    strong {
        font-family: 'Source-Bold';
    }

    @media (max-width: 818px) {
        padding: 5rem 2rem 0 2rem;
    }
`;

export const Titles = styled.div`
    font-family: 'Source-Bold', sans-serif;
    text-align: center;
    margin-bottom: 4rem;
    font-size: 2.8rem;
    line-height: 1.4em;
`;