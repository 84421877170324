import styled from 'styled-components';

export const Skills = styled.div`
    max-width: 63rem;
    margin: 0 auto;
`;

export const Skill = styled.div`
`;

export const SkillSection = styled.div`
    padding-bottom: 5rem;
    h3 {
        font-family: 'Source-Bold', sans-serif;
        font-size: 4rem;
        text-align: center;
        padding-bottom: 3rem;
    }

    .subhead {
        font-family: 'Source', sans-serif;
        font-size: 2rem;
        line-height: 3rem;
        padding-bottom: 3rem;

        @media (max-width: 414px) {
            padding: 0 2rem 3rem 2rem;
            display: none;
        }
    }
`;

export const SkillContainer = styled.div`
    margin: .8rem 0;
`;

export const SkillInside = styled.div`
    padding: .5rem 2rem;
    font-family: 'Source-Bold', sans-serif;
    font-size: 1.5rem;

    p {
        color: white;
    }

    &.p1.Marketing, &.p2.Marketing {
        background: linear-gradient(to right, #e66465 0%, #AA88BE 70%, #AA88BE 70%, #fadc41 100%) !important;
    }

    &.p1.Tech, &.p2.Tech {
        background: linear-gradient(to right, #4a99b7 0%, #73c496 70%, #73c496 70%, #fadc41 100%) !important;
    }

    &.Tech {
        background: linear-gradient(to right, #4a99b7, #85d788);
    }
    &.Marketing {
        background: linear-gradient(to right, #e66465, #9198e5);
    }

    &.Tools {
        background: linear-gradient(to right, #403f3f, #676c9e);
    }

    &.w20 {
        width: 20%;
    }
    &.w25 {
        width: 25%;
    }
    &.w30 {
        width: 30%;
    }
    &.w35 {
        width: 35%;
    }
    &.w40 {
        width: 40%;
    }
    &.w45 {
        width: 45%;
    }
    &.w50 {
        width: 50%;
    }
    &.w55 {
        width: 55%;
    }
    &.w60 {
        width: 60%;
    }
    &.w65 {
        width: 65%;
    }
    &.w70 {
        width: 70%;
    }
    &.w75 {
        width: 75%;
    }
    &.w80 {
        width: 80%;
    }
    &.w85 {
        width: 85%;
    }
    &.w90 {
        width: 90%;
    }
    &.w95 {
        width: 95%;
    }
    &.w100 {
        width: 100%;
    }
`;

export const Tools = styled.div`
    max-width: 63rem;
    margin: 0 auto;
    margin-bottom: 6rem;

    h2 {
        font-family: 'Source-Bold', sans-serif;
        font-size: 4rem;
        text-align: center;
        padding-bottom: 1rem;
    }
`;

export const ToolCategory = styled.div`
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding-bottom: 1rem;
    min-height: 7rem;

    @media (max-width: 414px) {
        gap: .1rem;
    }
`;

export const ToolCategoryName = styled.h3`
    font-family: 'Source-Bold', sans-serif;
    font-size: 2.4rem;
    line-height: 2rem;
    padding: 2rem 0 2rem;
    margin: 3rem 0 2rem;
    text-align: center;
    color: white;
    background: linear-gradient(to right, #403f3f, #676c9e);

    @media (max-width: 414px) {
        font-size: 2rem;
        color: black;
        line-height: 2rem;
        padding: 2rem 0 1.5rem;
        margin: 0;
        background: none;
    }
`;

export const ToolMobile = styled.div`
    @media (min-width: 414px) {
        display: none;
    }
`;



export const ToolName = styled.div`
    margin: .3rem .3rem;
    background-color: #403f3f;
    border-radius: 3rem !important;
    transition: background-color .3s ease-in-out;

    &.Activity {
        background-color: rgba(0,0,0,.45)
    }
    &.Industry {
        background-color: rgba(0,0,0,.53)
    }
    &.Audience {
        background-color: rgba(0,0,0,.68)
    }
    &.Type {
        background-color: rgba(0,0,0,.8)
    }

    &:hover {
        background-color: rgba(255,255,255,.9)
    }

    p {
        font-family: 'Source', sans-serif;
        padding: .07rem 1.7rem .07rem 1.7rem;
        line-height: 3rem;
        font-size: 1.5rem;
        color: white;
        text-decoration: none;
        .svg {
            position: absolute;
            top: .9rem;
            text-decoration: none;
        }
    }
`;

const ToolBase = styled.div`
    flex-grow: 1;
    width: 100%;
    margin-bottom: 1.5rem;

    @media (max-width: 414px) {
        display: none;
    }

    .gatsby-image-wrapper {
        width: 100%;
        height: auto; 
    }
`;

export const ToolXL = styled(ToolBase)`
    max-width: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
`;

export const ToolL = styled(ToolBase)`
    max-width: 17rem;
    padding-left: 1rem;
    padding-right: 1rem;
`;

export const Tool = styled(ToolBase)`
    max-width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
`;

export const ToolS = styled(ToolBase)`
    max-width: 13rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 1rem;
`;

export const ToolXS = styled(ToolBase)`
    max-width: 9rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 1rem;
`;