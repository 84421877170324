import styled from 'styled-components';

export const Blurb = styled.section`
    padding: 0 2rem 6rem 2rem;
    max-width: 100rem;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    a {
        color: green !important;
        text-decoration: underline !important;
    }

    h1, h2, h3, h4, h5, h6, p, li {
        font-family: 'Source', sans-serif;
    }

    h2, h3, h4, strong {
        font-family: 'Source-Bold', sans-serif; 
    }

    h2 {
        font-family: 'Source-Bold', sans-serif;
        text-align: center;
        margin-bottom: 4rem;
        font-size: 4rem;
        line-height: 1.4em;
    }

    h2:nth-of-type(2) {
        margin-top: 6rem;
    }

    h3 {
        font-size: 2.5rem;
        font-family: 'Source', sans-serif;
    }

    p, li {
        margin: 1.6rem 0 0;
        line-height: 3rem;
        font-size: 2rem;
    }

    li {
        margin: 0 !important;
    }

    @media (max-width: 818px) {
        padding: 5rem 2rem 0 2rem;
    }
`;