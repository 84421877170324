import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Timeline from 'components/ui/Timeline';
import FormatHtml from 'components/utils/FormatHtml';

import * as Styled from './styles';

const Education = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "education" } } }
        sort: { order: DESC, fields: fileAbsolutePath }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              university
              degree
              startDate
              endDate
              logo {
                childImageSharp {
                  fixed (height: 80) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const education = allMarkdownRemark.edges;

  return (
    <Styled.Education section>
      <Styled.Titles>
        <h2>Education</h2>
      </Styled.Titles>
      {education.map((item) => {
        const {
          id,
          html,
          frontmatter: { university, degree, startDate, endDate, logo }
        } = item.node;

        return (
          <Timeline
            key={id}
            title={university}
            subtitle={degree}
            html={<FormatHtml content={html} />}
            mobile={<FormatHtml content={html} />}
            startDate={startDate}
            endDate={endDate}
            logo={logo}
            jobs={[]}
            tags={[]}
          />
        );
      })}
    </Styled.Education>
  );
};

export default Education;
