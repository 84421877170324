import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import * as Styled from './styles';

const Timeline = (props) => {
  
  const tags = props.tags.map((tag, i) => {
    
    return (
        <Styled.Tag className={tag.category} key={`key${i}`}>
          <p>{tag.name}</p>
        </Styled.Tag>
    )
  });
  
  return (
  <Styled.Timeline>
    <Styled.Point />
    <Styled.Details>
      <div className='headlines'>
        <Img fixed={props.logo.childImageSharp.fixed} alt={props.title} />
        <Styled.Title>{props.title}</Styled.Title>
        <Styled.Subtitle>{props.subtitle}</Styled.Subtitle>
        <Styled.Date>
          {`${props.startDate} ${props.startDate ? '–' : ''} ${props.endDate}`}
        </Styled.Date>
        {props.jobs.map((job, i) => {
          return (
          <Styled.Jobs key={i}>
            <h5>{job.title}</h5>
            <p>{`${job.start} – ${job.end}`}</p>
          </Styled.Jobs>
          )
        })}
      </div>
    </Styled.Details>
    <Styled.Content>{props.html}</Styled.Content>
    <Styled.Mobile>{props.mobile}</Styled.Mobile>
  </Styled.Timeline>
);
  }

Timeline.propTypes = {
  title: PropTypes.any.isRequired,
  subtitle: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  jobs: PropTypes.array.isRequired
};

export default Timeline;
