import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'components/ui/Container';

import styled from 'styled-components';

import { FaUsers, FaPaintBrush, FaCode, FaChartLine, FaPen, FaBullhorn } from 'react-icons/fa';

import * as Styled from './styles';

const Users = styled(FaUsers)`
  font-size: 40px;
`;

const Brush = styled(FaPaintBrush)`
  font-size: 40px;
`;

const Code = styled(FaCode)`
  font-size: 40px;
`;

const Chart = styled(FaChartLine)`
  font-size: 40px;
`;

const Bullhorn = styled(FaBullhorn)`
  font-size: 40px;
`;

const Pen = styled(FaPen)`
  font-size: 40px;
`;

const Services = () => {
  const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "services section" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(filter: { frontmatter: { category: { eq: "services" } } }, sort: { fields: fileAbsolutePath }) {
        edges {
          node {
            id
            frontmatter {
              title
              icon
              description
            }
          }
        }
      }
    }
  `);



  const sectionTitle = markdownRemark.frontmatter;
  const services = allMarkdownRemark.edges;

  return (
    <Container section pushup="true">
      <Styled.Titles>
        <h2>{sectionTitle.title}</h2>
      </Styled.Titles>
      <Styled.Services>
        {services.map((item) => {
          const {
            id,
            frontmatter: { title, icon, description }
          } = item.node;
          const faIcon = 
            icon === 'brush' ? <Brush /> :
            icon === 'users' ? <Users /> :
            icon === 'bullhorn' ? <Bullhorn /> :
            icon === 'pen' ? <Pen /> :
            icon === 'code' ? <Code /> : <Chart />
          return (
            <Styled.Post key={id}>
              <Styled.Card>
                <Styled.Category className={icon}>
                  <h3>{title}</h3>
                  <p>{faIcon}</p>
                </Styled.Category>
                <Styled.Content className="content">
                  <Styled.Description><p>{description}</p></Styled.Description>
                </Styled.Content>
              </Styled.Card>
            </Styled.Post>
            // <Styled.ServiceItem key={id}>
            //   <InfoBlock icon={icon} title={title} content={description} />
            // </Styled.ServiceItem>
          );
        })}
      </Styled.Services>
    </Container>
  );
};

export default Services;
