import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Container from 'components/ui/Container';

import * as Styled from './styles';

const Skills = () => {
  const {
    markdownRemark,
    hubspot,
    customer,
    webflow,
    prismic,
    contentful,
    ga,
    outgrow,
    optinmonster,
    dripify,
    bonjoro,
    drift,
    figma,
    frase,
    fullstory,
    gitbook,
    hootsuite,
    hotjar,
    illustrator,
    intercom,
    semrush,
    zapier,
    wordstream,
    whimsical,
    miro
  } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "skills section" } }) {
        frontmatter {
          title
          subtitle
          skills {
            skill
            category
            value
            priority
          }
        }
      }
      hubspot: file(relativePath: { eq: "logos/hubspot.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      webflow: file(relativePath: { eq: "logos/webflow.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      prismic: file(relativePath: { eq: "logos/prismic.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contentful: file(relativePath: { eq: "logos/contentful.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      customer: file(relativePath: { eq: "logos/customer.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      salesforce: file(relativePath: { eq: "logos/salesforce.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ga: file(relativePath: { eq: "logos/ga.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tagmanager: file(relativePath: { eq: "logos/tagmanager.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      searchconsole: file(relativePath: { eq: "logos/searchconsole.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      optinmonster: file(relativePath: { eq: "logos/optinmonster.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gitbook: file(relativePath: { eq: "logos/gitbook.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      outgrow: file(relativePath: { eq: "logos/outgrow.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dripify: file(relativePath: { eq: "logos/dripify2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      asana: file(relativePath: { eq: "logos/asana.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bonjoro: file(relativePath: { eq: "logos/bonjoro.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      buffer: file(relativePath: { eq: "logos/buffer.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      canva: file(relativePath: { eq: "logos/canva.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clickup: file(relativePath: { eq: "logos/clickup.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      confluence: file(relativePath: { eq: "logos/confluence.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      drift: file(relativePath: { eq: "logos/drift.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      figma: file(relativePath: { eq: "logos/figma.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fullstory: file(relativePath: { eq: "logos/fullstory.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hootsuite: file(relativePath: { eq: "logos/hootsuite2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hotjar: file(relativePath: { eq: "logos/hotjar.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      illustrator: file(relativePath: { eq: "logos/illustrator.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      intercom: file(relativePath: { eq: "logos/intercom.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jira: file(relativePath: { eq: "logos/jira.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      linear: file(relativePath: { eq: "logos/linear.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      monday: file(relativePath: { eq: "logos/monday.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      notion: file(relativePath: { eq: "logos/notion.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      optinmonster: file(relativePath: { eq: "logos/optinmonster.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      readme: file(relativePath: { eq: "logos/readme.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      semrush: file(relativePath: { eq: "logos/semrush.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zapier: file(relativePath: { eq: "logos/zapier.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wordstream: file(relativePath: { eq: "logos/wordstream.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      segment: file(relativePath: { eq: "logos/segment.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      frase: file(relativePath: { eq: "logos/frase.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      chatgpt: file(relativePath: { eq: "logos/chatgpt.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      whimsical: file(relativePath: { eq: "logos/whimsical.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      miro: file(relativePath: { eq: "logos/miro.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800, duotone: { highlight: "#ffffff", shadow: "#000000" }) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const skills = markdownRemark.frontmatter.skills.sort((a, b) => a.priority - b.priority).sort((a, b) => b.value - a.value);

  const categories = skills.reduce((acc,el) => {
    if (!acc[el.category]) {
      acc[el.category] = [];
    }
    acc[el.category].push({skill: el.skill, value: el.value, priority: el.priority});
    return acc;
  }, []);

  const extractedCategories = Object.keys(categories);
  const categoryNames = [extractedCategories[2], extractedCategories[0]]

  const categoryBlurb = {
    "Marketing Skills": "",
    "Tech Skills": "Trained as a software developer. I code confidently in full-stack JavaScript.",
    Tools: "",
  }

  return (
    <Container section>
      <Styled.Skills>
      {
      categoryNames.map((categoryName, i) => {
        return (
          <Styled.SkillSection key={i}>
            <h3>{categoryName}</h3>
            <p className="subhead">{categoryBlurb[categoryName]}</p>
            {categoryName !== 'Tools' && categories[categoryName].map((category, j) => (
              <Styled.SkillContainer key={j}>
                <Styled.SkillInside className={`w${category.value} ${categoryName} p${category.priority}`}>
                  <p>{category.skill}</p>
                </Styled.SkillInside>
              </Styled.SkillContainer>
            ))}
          </Styled.SkillSection>
        );
      })
}
      </Styled.Skills>
      <Styled.Tools>
        <h2>Tools</h2>
        <Styled.ToolCategoryName>Marketing Automation & CRM</Styled.ToolCategoryName>
        <Styled.ToolCategory>
          <Styled.Tool>
            <Img fluid={hubspot.childImageSharp.fluid} alt='Tool' />
          </Styled.Tool>
          <Styled.ToolXL>
            <Img fluid={customer.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolXL>
          <Styled.ToolMobile>
            <Styled.ToolName><p>HubSpot</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Customer.io</p></Styled.ToolName>
          </Styled.ToolMobile>
        </Styled.ToolCategory>

        <Styled.ToolCategoryName>Content Management & Analytics</Styled.ToolCategoryName>
        <Styled.ToolCategory>
          <Styled.ToolL>
            <Img fluid={semrush.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolL>
          <Styled.ToolS>
            <Img fluid={webflow.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolS>
          <Styled.ToolL>
            <Img fluid={contentful.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolL>
          <Styled.ToolL>
            <Img fluid={prismic.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolL>
          <Styled.ToolS>
            <Img fluid={wordstream.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolS>
          <Styled.Tool>
            <Img fluid={ga.childImageSharp.fluid} alt='Tool' />
          </Styled.Tool>
          <Styled.ToolS>
            <Img fluid={frase.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolS>
          <Styled.ToolS>
            <Img fluid={hotjar.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolS>
          <Styled.ToolS>
            <Img fluid={fullstory.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolS>
          <Styled.ToolMobile>
            <Styled.ToolName><p>SEMRush</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Webflow</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Contentful</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Prismic</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Wordstream</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Google Analytics</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Frase AI</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Hotjar</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Fullstory</p></Styled.ToolName>
          </Styled.ToolMobile>
        </Styled.ToolCategory>

        <Styled.ToolCategoryName>Lead Gen & Lead Management</Styled.ToolCategoryName>
        <Styled.ToolCategory>
          <Styled.ToolL>
            <Img fluid={outgrow.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolL>
          <Styled.ToolXL>
            <Img fluid={optinmonster.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolXL>
          <Styled.ToolS>
            <Img fluid={dripify.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolS>
          <Styled.ToolL>
            <Img fluid={hootsuite.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolL>
          <Styled.ToolS>
            <Img fluid={drift.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolS>
          <Styled.ToolL>
            <Img fluid={intercom.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolL>
          <Styled.ToolL>
            <Img fluid={bonjoro.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolL>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Outgrow</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Optinmonster</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Dripify</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Hootsuite</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Drift</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Intercom</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Bonjoro</p></Styled.ToolName>
          </Styled.ToolMobile>
        </Styled.ToolCategory>

        <Styled.ToolCategoryName>Design</Styled.ToolCategoryName>
        <Styled.ToolCategory>
          <Styled.Tool>
            <Img fluid={figma.childImageSharp.fluid} alt='Tool' />
          </Styled.Tool>
          <Styled.ToolXS>
            <Img fluid={illustrator.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolXS>

          <Styled.ToolMobile>
            <Styled.ToolName><p>Figma</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Illustrator</p></Styled.ToolName>
          </Styled.ToolMobile>
        </Styled.ToolCategory>

        <Styled.ToolCategoryName>Utilities</Styled.ToolCategoryName>
        <Styled.ToolCategory>
          <Styled.ToolS>
            <Img fluid={zapier.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolS>
          <Styled.ToolL>
            <Img fluid={gitbook.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolL>
          <Styled.ToolL>
            <Img fluid={whimsical.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolL>
          <Styled.ToolS>
            <Img fluid={miro.childImageSharp.fluid} alt='Tool' />
          </Styled.ToolS>

          <Styled.ToolMobile>
            <Styled.ToolName><p>Zapier</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>GitBook</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Whimsical</p></Styled.ToolName>
          </Styled.ToolMobile>
          <Styled.ToolMobile>
            <Styled.ToolName><p>Miro</p></Styled.ToolName>
          </Styled.ToolMobile>
        </Styled.ToolCategory>


      </Styled.Tools>
    </Container>
  );
};

export default Skills;