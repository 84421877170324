import styled from 'styled-components';

export const Titles = styled.div`
  padding: 0 2rem 0 2rem;

  h2 {
    font-family: 'Source-Bold', sans-serif;
    text-align: center;
    margin-bottom: 4rem;
    font-size: 4rem;
    line-height: 1.4em;
    position: relative;
    z-index: 2;
  }
`;

export const Services = styled.div`
    max-width: 700px;
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 6rem;
    display: flex;
    flex-wrap: wrap;
`;

export const Icon = styled.span`
`;

export const Post = styled.div`
    width: 50%;
    z-index: 1;
    
    box-sizing: border-box;
    position: relative;
    font-family: 'Source', sans-serif;
    display: inline-block;

    p, span {
      text-decoration: none;
    }

    @media (max-width: 800px) {
      width: 90%;
      margin: 0 auto;
    }

    @media (min-width: 800px) {
      height: 21rem;

    &:nth-child(1) {
      width: 100%;
      height: 16.7rem;
      & > div {
        margin: 0 auto;
        width: 96%;
        .content {
          height: 9rem;
        }
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      width: 50%;
      height: 19.5rem;
      & > div {
        margin: 0 auto;
        width: 92%;
        .content {
          height: 11.6rem;
        }
      }
    }

    &:nth-child(n+4) {
      width: 33.333%;
      height: 30rem;
      & > div {
        margin: 0 auto;
        width: 88%;
        .content {
          height: 16.7rem;
        }
      }
    }
    }
`;

export const Card = styled.div`
    margin: 2rem auto;
    background-color: #FFF;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, .3);
    border-radius: 3px;
    margin: 0 auto;
    margin-bottom: 3rem;
    position: relative;
    transition: all .4s ease;

    @media (max-width: 414px) {
      margin-bottom: 2rem;
    }

    &:hover {
      transform: scale(1.04);
      box-shadow: 0 2.5rem 6rem rgba(0, 0, 0, .3);
    }

    &:active {
      transform: scale(0.98);
    }

    .brush {
      background-color: #7b9199;
    }

    .Tech span, .Tech {
        background-color: #337995;
    }

    .Statistics span, .Statistics {
        background-color: #337995;
    }

    .Careers span, .Careers {
        background-color:#E9DA65;
    }

    .code {
      background-color:#7b9199;
    }

    .pen {
      background-color: #C7467E;
    }

    .bullhorn {
      background-color: #C7467E;
    }

    .users {
        /* background-color: #CF804A; */
        background-color:#5e6e74;
    }

    .General span, .General {
        background-color: #32373B;
    }

    .graph {
        background-color: #BA2B4A;
    }
`;

export const Category = styled.div`
    height: 50px;
    h3, h3:visited, h3:active, h3:hover{
        color: #FFF;
        text-decoration: none !important;
        font-family: Source;
        font-size: 1.8rem;
        line-height: 3rem;
        position: absolute;
        top: 1.1rem;
        left: 2rem;
        max-width: 75%;
        color: white;
    }

    p {
    position: absolute;
    right: 1.4rem;
    top: 0;
    height: 7rem;
    padding-top: 1.4rem;
    color: white;

    svg {
      font-size: 2.5rem;
    }
    }
`;

export const Content = styled.div`
    
    svg {
        position: absolute;
        left: 3rem;
        top: 2rem;
        font-size: 3rem;
    }

    @media (min-width: 414px) {
      height: 12rem;
    }

`;


export const Title = styled.h3`
    line-height: 3.25rem;
    position: absolute;
    top: 2rem;
    left: 8rem;
    width: 80%;
    color: white;
    
    span {
      @media (min-width: 400px)  {
        font-size: 2.3rem !important;
      }
      @media (max-width: 400px)  {
        font-size: 2rem !important;
      }
      background-image: linear-gradient(
        to right bottom, 
        rgba(0, 0, 0, .14), 
        rgba(255, 255, 255, .26)
        );
      padding: 0.5rem 2rem;
    }
`;

export const Description = styled.div`
    width: 100%;

    p {
      @media (min-width: 400px)  {
        font-size: 1.8rem !important;
        line-height: 2.7rem;
      }
      @media (max-width: 400px)  {
        font-size: 1.7rem !important;
        line-height: 2.6rem;
      }
      color: #000;
      padding: 1.8rem;
    }
`;

export const Date = styled.time`
    
`;

export const Tags = styled.div`
    position: absolute;
    bottom: .1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1.8rem;
    max-width: 85%;
    font-size: 1.6rem;
`;

export const Tag = styled.span`
    padding: .6rem 1rem;
    color: #000;
    text-decoration: none;
`;
