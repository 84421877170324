import React from 'react';

import Container from 'components/ui/Container';
import FormatHtml from 'components/utils/FormatHtml';

import * as Styled from './styles';

const Blurb = (props) => {

  return (
    <Container section>
      <Styled.Blurb>
        <FormatHtml content={props.html} />
      </Styled.Blurb>
    </Container>
  );
};

export default Blurb;